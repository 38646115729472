import $ from 'jquery'

export default class Redirect {
  start() {
    this.openRedirect()
  }

  openRedirect() {
    $(document).on('ready turbolinks:load', function () {
      if ($('.js-redirect').length) {
        const href = $('.js-redirect').attr('href')
        if (href){
          setTimeout(function () {
            location.href = href;
          }, 500)
        }    
      }
    })
  }
}
