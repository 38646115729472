import Tab from './Tab'
import ApplicationForm from './ApplicationForm'
import Alert from './Alert'
import Accordion from './Accordion'
import Redirect from './Redirect'
import ReturnAccordion from './ReturnAccordion'
import TurbolinksOptimizer from './TurbolinksOptimizer'

export default class App {
  constructor() {
    this.tabController = new Tab()
    this.applicationForm = new ApplicationForm()
    this.alert = new Alert()
    this.redirect = new Redirect()
    this.accordion = new Accordion()
    this.returnAccordion = new ReturnAccordion()
    this.turbolinksOptimizer = new TurbolinksOptimizer()
  }

  start() {
    this.tabController.start()
    this.applicationForm.start()
    this.alert.start()
    this.redirect.start()
    this.accordion.start()
    this.returnAccordion.start()
    this.turbolinksOptimizer.start()
  }
}
